import classes from "./Programs.module.css";
import Rightarrow from "../assets/rightArrow.png";
import { programsData } from "../data/programsData";
const Programs = () => {
  return (
    <div className={classes.programs} id="programs">
      <div className={classes.programs_header}>
        <span className="stroke_text ">Explore our</span>
        <span>Programs</span>
        <span className="stroke_text">to shape you</span>
      </div>

      <div className={classes.program_catogories}>
        {programsData.map((program) => (
          <div className={classes.category}>
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className={classes.join_now}>
              <span>Join Now</span>
              <img src={Rightarrow} alt="arrow" className={classes.arrow}></img>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
