import classes from "./Test.module.css";
import { testimonialsData } from "../data/testimonialsData";
import { useState } from "react";
import leftArrow from "../assets/leftArrow.png";
import RighttArrow from "../assets/rightArrow.png";
import { motion } from "framer-motion";
const Test = () => {
  const [selected, setSelected] = useState(0);
  const tLength = testimonialsData.length;
  return (
    <div className={classes.test} id="test">
      <div className={classes.left_t}>
        <span>Testimonials</span>
        <span className="stroke_text">What they</span>
        <span>say about us</span>
        <span>{testimonialsData[selected].review}</span>
        <span>
          <span style={{ color: "var(--orange)" }}>
            {testimonialsData[selected].name}
          </span>
          - {testimonialsData[selected].status}
        </span>
      </div>
      <div className={classes.right_t}>
        <div></div>
        <div></div>
        <motion.img
          key={selected}
          initial={{ x: "5rem", opacity: 0 }}
          whileInView={{ x: "0rem", opacity: 1 }}
          exit={{ opacity: 0, x: "-5rem" }}
          src={testimonialsData[selected].image}
        />
        <div className={classes.arrows}>
          <img
            onClick={() => {
              selected === 0
                ? setSelected(tLength - 1)
                : setSelected((prev) => prev - 1);
            }}
            src={leftArrow}
            alt=""
          />
          <img
            onClick={() => {
              selected === tLength - 1
                ? setSelected(0)
                : setSelected((prev) => prev + 1);
            }}
            src={RighttArrow}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Test;
