import classes from "./Join.module.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mdhu6d9",
        "template_9vtlbc9",
        form.current,
        "zp51T1R3rhDNdi5FO"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className={classes.join} id="join-us">
      <div className={classes.left_j}>
        <hr></hr>
        <div>
          <span className="stroke_text">READY TO </span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke_text">WITH US?</span>
        </div>
      </div>
      <div className={classes.right_j}>
        <form
          ref={form}
          className={classes.email_container}
          onSubmit={sendEmail}
        >
          <input
            className={classes.place}
            type="email"
            name="user_email"
            placeholder="Enter Your Email Address"
          ></input>
          <button className={classes.btn}>Join now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
