import classes from "./Headers.module.css";
import logo from "../../src/assets/logo.png";
import bar from "../../src/assets/bars.png";
import { useState } from "react";
import { Link } from "react-scroll";
const Header = () => {
  const mobile = window.innerWidth <= 786 ? true : false;
  const [menuopened, setMenuOpened] = useState(false);
  return (
    <div className={classes.header}>
      <img src={logo} alt="logo" className={classes.logo} />
      {menuopened === false && mobile === true ? (
        <div
          style={{ backgroundColor: "var(--appColor)", padding: "0.3rem" }}
          onClick={() => setMenuOpened(true)}
        >
          <img
            style={{ width: "1.5rem", height: "1.5rem", zIndex: 99 }}
            src={bar}
          ></img>
        </div>
      ) : (
        <ul className={classes.header_menu}>
          <li>
            <Link
              to="home"
              smooth={true}
              spy={true}
              onClick={() => setMenuOpened(false)}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="programs"
              smooth={true}
              spy={true}
              onClick={() => setMenuOpened(false)}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              to="reasons"
              smooth={true}
              spy={true}
              onClick={() => setMenuOpened(false)}
            >
              Why us
            </Link>
          </li>
          <li>
            <Link
              to="plans"
              smooth={true}
              spy={true}
              onClick={() => setMenuOpened(false)}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              to="test"
              smooth={true}
              spy={true}
              onClick={() => setMenuOpened(false)}
            >
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
