import Header from "../Headers";
import classes from "./Hero.module.css";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";
const Hero = () => {
  const transition = { type: "tween", duration: 2 };
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className={classes.hero} id="home">
      <div className={classes.hero_blur}></div>
      <div className={classes.left_h}>
        <Header />
        {/* the best ad */}
        <div className={classes.the_best_ad}>
          <motion.div
            initial={{ left: mobile ? "145px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={transition}
          ></motion.div>
          <span>the best fitness club in town</span>
        </div>
        {/* Hero Heading */}
        <div className={classes.hero_text}>
          <div>
            <span className="stroke_text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* figures */}
        <div className={classes.figures}>
          <div>
            <span>
              <NumberCounter end={140} start={100} delay="4" prefix="+" />
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>
              <NumberCounter end={978} start={900} delay="4" prefix="+" />
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
              <NumberCounter end={50} start={20} delay="4" prefix="+" />
            </span>
            <span>fitness programs</span>
          </div>
        </div>
        <div className={classes.hero_buttons}>
          <button className={classes.btn}>Get Started</button>
          <button className={classes.btn}>Learn More</button>
        </div>
      </div>
      <div className={classes.right_h}>
        <button className={classes.btn}>Join now</button>

        <motion.div
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
          className={classes.heart_rate}
        >
          <img src={Heart} alt="heart"></img>
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        {/* hero image */}
        <img src={hero_image} alt="hero" className={classes.hero_image}></img>
        <motion.img
          transition={transition}
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          src={hero_image_back}
          alt="heroback"
          className={classes.hero_image_back}
        ></motion.img>

        {/* calories */}
        <motion.div
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
          className={classes.calories}
        >
          <img src={Calories} alt="calories"></img>
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
