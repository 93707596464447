import classes from "./Plans.module.css";
import { plansData } from "../data/plansData";
import whiteTick from "../assets/whiteTick.png";
const Plans = () => {
  return (
    <div className={classes.plans_container} id="plans">
      <div className={classes.plans_blur_1}></div>
      <div className={classes.plans_blur_2}></div>
      <div className={classes.programs_header}>
        <span className="stroke_text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke_text">NOW WITHUS</span>
      </div>
      <div className={classes.plans}>
        {plansData.map((plan, i) => (
          <div className={classes.plan} key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>
            <div className={classes.features}>
              {plan.features.map((feature, i) => (
                <div className={classes.feature}>
                  <img src={whiteTick}></img>
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See more benifits </span>
            </div>
            <button className={classes.btn}>Join now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
