import classes from "./Reasons.module.css";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import nb from "../assets/nb.png";
import nike from "../assets/nike.png";
import adidas from "../assets/adidas.png";
import tick from "../assets/tick.png";

const Reasons = () => {
  return (
    <div className={classes.reasons} id="reasons">
      <div className={classes.left_r}>
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className={classes.right_r}>
        <span>some reasons</span>
        <div>
          <span className="stroke_text">Why </span>
          <span>choose us?</span>
        </div>

        <div className={classes.details_r}>
          <div>
            <img src={tick}></img>
            <span>OVER 140+ EXPERT COACHS</span>
          </div>
          <div>
            <img src={tick}></img>
            <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
          </div>
          <div>
            <img src={tick}></img>
            <span>FREE PROGRAM FOR NEW MEMBER </span>
          </div>
          <div>
            <img src={tick}></img>
            <span>RELIABLE PARTNERS</span>
          </div>
        </div>
        <span style={{ color: "var(--gray)", fontWeight: "normal" }}>
          OUR PARTNERS
        </span>
        <div className={classes.partners}>
          <img src={nb} />
          <img src={adidas} />
          <img src={nike} />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
